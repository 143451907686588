/* stylelint-disable-next-line import-notation */
@import "globals";

.no-select {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/** FontAwesome override **/
.fa-align-middle {
  vertical-align: baseline !important;
}

/** Color utilities */
.gray-color {
  color: #859399;
}

.text-brand-primary-color {
  color: $brand-primary-color;
}

.text-dark-grey {
  color: $brand-dark-grey;
}

/** Position utilities **/
.vertical-align-middle {
  vertical-align: middle !important;
}

/** Flex Utilities **/
.flex-1 {
  flex: 1;
}